import React, { useEffect, useState } from "react";
import axios from "axios";
import { PRODUCTS_ALL, PRODUCTS_DELETE } from "../../../../../constanse/webservices";
import { CATEGORY_ALL } from "../../../../../constanse/webservices";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Edit2, Eraser } from "iconsax-react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import masterCardLogo from "assets/images/home/kaktus.png";
import coinIcon from "assets/images/icons/coin.png";
import Api from "Api";
import MDBox from "../../../../../components/MDBox";
import { Link } from "react-router-dom";
import MDButton from "../../../../../components/MDButton";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { Cast } from "@mui/icons-material";
const BASE_IMAGE_URL = "https://ketabtusapi.tribitgroup.com/"; // stage - dev

const ProductsList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [products, setproducts] = React.useState(false);
  const [catigories, setCatigories] = React.useState(false);

  useEffect(() => {
    new Api()
      .post(CATEGORY_ALL, {
        body: JSON.stringify({
          page: 0,
          count: 0,
        }),
      })
      .then((c) => c.json())
      .then((c) => {
        setCatigories(c);
      });
    new Api()
      .post(PRODUCTS_ALL, {
        body: JSON.stringify({
          page: 0,
          count: 0,
        }),
      })
      .then((c) => c.json())
      .then((c) => {
        setproducts(c);
      });
    // console.log(products);
  }, []);
  const product = products?.result?.map((item, index) => {
    const nameObj = catigories?.result?.find((item2) => {
      return item2.id === item.categoryId;
    });
    let catTitle = nameObj?.title;
    return {
      ...item,
      catTitle,
    };
  });

  return (
    <MDBox mb={2}>
      <div style={{ margin: "0 0px 16px", width: "100%" }}>
        <MDButton
          circular={false}
          iconOnly={false}
          color={"success"}
          variant="gradient"
          style={{ width: "fit-content" }}
        >
          <Link
            to="/products/add"
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            افزودن محصول جدید
          </Link>
        </MDButton>
      </div>

      <div>
        <MDBox>
          <Grid container spacing={3}>
            {product?.map((item) => {
              return (
                <Grid item xs={12} md={6} lg={3}>
                  <Paper
                    elevation={1}
                    key={item}
                    my={2}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        // padding: 24,
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox
                        component="img"
                        src={BASE_IMAGE_URL + item.photoUrl}
                        alt="kaktus"
                        width="100%"
                        height={"200px"}
                        borderRadius={"6px"}
                        objectFit={"cover"}
                      />
                    </div>

                    <div style={{ padding: "15px 16px" }}>
                      <Typography fontSize="18px!important">{item.name}</Typography>
                      <Typography fontSize="10px!important" py="8px">
                        {item.description}
                      </Typography>
                      <Grid item style={{ display: "flex" }}>
                        <MDBox component="img" src={coinIcon} alt="coin" width="fit-content" />
                        <Typography fontSize="12px!important" pl="8px">
                          {item.coinPrice}
                        </Typography>
                      </Grid>

                      <Grid item style={{ display: "flex" }}>
                        <MDBox component="img" src={coinIcon} alt="coin" width="fit-content" />
                        <Typography fontSize="12px!important" pl="8px">
                          {item.coinOffPrice} OFF
                        </Typography>
                      </Grid>

                      <Grid item style={{ display: "flex" }}>
                        <Typography fontSize="12px!important">دسته بندی: </Typography>
                        <Typography fontSize="12px!important" pl="8px">
                          {item.catTitle}
                        </Typography>
                      </Grid>
                      <Grid item style={{ display: "flex" }}>
                        <Typography fontSize="12px!important">تعداد: </Typography>
                        <Typography fontSize="12px!important" pl="8px">
                          {item.unit}
                        </Typography>
                      </Grid>
                    </div>
                    <Divider />
                    <Grid container justifyContent="flex-end">
                      <Grid width={"100%"}>
                        <MDButton
                          circular={false}
                          iconOnly={false}
                          color={"primary"}
                          variant="gradient"
                          style={{
                            width: "50%",
                            background: "black !important",
                            color: "red!important",
                            boxShadow: "none",
                          }}
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <Link
                            to={"/products/edit/" + item.id}
                            style={{ display: "flex", alignItems: "center", color: "white" }}
                          >
                            <Edit2 size="15" />
                            ویرایش محصول
                          </Link>
                        </MDButton>
                        <MDButton
                          circular={false}
                          iconOnly={false}
                          color={"secondary"}
                          variant="gradient"
                          style={{
                            width: "50%",
                            background: "black !important",
                            color: "red!important",
                            boxShadow: "none",
                          }}
                          onClick={() => {
                            if (confirm("آبا مطمعن هستید؟")) {
                              axios.delete(`${PRODUCTS_DELETE}/${item.id}`, {
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem("adminAccess")}`,
                                },
                              });
                            }
                          }}
                        >
                          <Edit2 size="15" />
                          حذف محصول
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
      </div>
    </MDBox>
  );
};

export default ProductsList;
