/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  ACTIVE_CODE,
  ADD_DRIVER_ROLE,
  PHONE_EXIST,
  REGISTER_DRIVER,
  SEND_CODE,
} from "../../../constanse/webservices";

function Basic() {
  const navigate = useNavigate();

  const [isRegister, setIsRegister] = useState(-1);
  const [phoneActivate, setPhoneActivate] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    email: "",
    activationCode: "",
    maxWasteInKG: "",
    valideUntil: "",
    carType: "",
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSubmit = (e) => {
    axios
      .post(
        `${REGISTER_DRIVER}`,
        {
          firstName: data?.firstName,
          lastName: data?.lastName,
          phoneNumber: data?.phoneNumber,
          password: data?.password,
          email: data?.email,
          activationCode: data?.activationCode,
          maxWasteInKG: data?.maxWasteInKG,
          valideUntil: data?.valideUntil,
          carType: data?.carType,
          numberPlate: "string",
          dateOfBirth: "string",
        },
        { headers: { Authorization: `${localStorage.getItem("adminAccess")}` } }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetCode = (e) => {
    e.preventDefault();
    axios
      .get(`${PHONE_EXIST}/${data?.phoneNumber}`)
      .then((res) => {
        setIsRegister(0);
      })
      .catch((err) => {
        setIsRegister(1);
        axios
          .get(`${SEND_CODE}?phoneNumber=${data?.phoneNumber}`)
          .then((rest) => console.log(rest))
          .catch((error) => console.log(error));
      });
  };

  const handleSetCode = () => {
    axios
      .post(`${ACTIVE_CODE}?phoneNumber=${data?.phoneNumber}&code=${data.activationCode}`)
      .then((rest) => setIsRegister(2))
      .catch((error) => console.log(error));
  };

  const handleSetDriver = (e) => {
    e.preventDefault();
    axios
      .post(
        `${ADD_DRIVER_ROLE}/${data?.phoneNumber}`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` } }
      )
      .then((res) => {
        navigate("/profile");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const RenderForm = () => {
    switch (isRegister) {
      case 0:
        return (
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDTypography variant="subtitle2" fontWeight="normal" align="end">
                  کاربری قبلا با این شماره تلفن ثبت نام کرده است.
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="success" fullWidth onClick={handleSetDriver}>
                  افزودن کاربر به لیست رانندگان
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        );
      case 1:
        return (
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  textAlign="center"
                  type="text"
                  label="کد تایید"
                  fullWidth
                  name={"activationCode"}
                  value={data?.activationCode}
                  onChange={(e) => setData({ ...data, activationCode: e.target.value })}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="success" fullWidth onClick={handleSetCode}>
                  تایید
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        );
      case 2:
        return (
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  textAlign="center"
                  type="text"
                  label="نام"
                  fullWidth
                  name={"firstName"}
                  value={data?.firstName}
                  onChange={(e) => setData({ ...data, firstName: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="نام خانوادگی"
                  fullWidth
                  name={"lastName"}
                  value={data?.lastName}
                  onChange={(e) => setData({ ...data, lastName: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="تلفن"
                  fullWidth
                  name={"phoneNumber"}
                  value={data?.phoneNumber}
                  onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="ایمیل"
                  fullWidth
                  name={"email"}
                  value={data?.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  name={"password"}
                  value={data?.password}
                  onChange={(e) => setData({ ...data, password: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="maxWasteInKG"
                  fullWidth
                  name={"maxWasteInKG"}
                  value={data?.maxWasteInKG}
                  onChange={(e) => setData({ ...data, maxWasteInKG: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="valideUntil"
                  fullWidth
                  name={"valideUntil"}
                  value={data?.valideUntil}
                  onChange={(e) => setData({ ...data, valideUntil: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2}>
                <Select
                  variant={"outlined"}
                  fullWidth
                  style={{ height: 40 }}
                  onChange={(e) => setData({ ...data, carType: e.target.value })}
                >
                  <MenuItem value={0}>سواری</MenuItem>
                  <MenuItem value={1}>وانت</MenuItem>
                </Select>
              </MDBox>
              {/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
              {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe} />*/}
              {/*  <MDTypography*/}
              {/*    variant="button"*/}
              {/*    fontWeight="regular"*/}
              {/*    color="text"*/}
              {/*    onClick={handleSetRememberMe}*/}
              {/*    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
              {/*  >*/}
              {/*    &nbsp;&nbsp;Remember me*/}
              {/*  </MDTypography>*/}
              {/*</MDBox>*/}
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                  ثبت نام
                </MDButton>
              </MDBox>
              {/*  <MDBox mt={3} mb={1} textAlign="center">*/}
              {/*    <MDTypography variant="button" color="text">*/}
              {/*      Don&apos;t have an account?{" "}*/}
              {/*      <MDTypography*/}
              {/*        component={Link}*/}
              {/*        to="/authentication/sign-up"*/}
              {/*        variant="button"*/}
              {/*        color="info"*/}
              {/*        fontWeight="medium"*/}
              {/*        textGradient*/}
              {/*      >*/}
              {/*        Sign up*/}
              {/*      </MDTypography>*/}
              {/*    </MDTypography>*/}
              {/*  </MDBox>*/}
            </MDBox>
          </MDBox>
        );
      default:
        return (
          <form>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    textAlign="center"
                    type="text"
                    label="تلفن"
                    fullWidth
                    name={"phoneNumber"}
                    value={data?.phoneNumber}
                    onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="success" fullWidth onClick={handleGetCode}>
                    ثبت نام
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </form>
        );
    }
  };

  console.log(data, isRegister, phoneActivate);
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={1}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            ثبت نام راننده
          </MDTypography>
        </MDBox>
        {RenderForm()}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
