import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { USER_ACTIVE } from "..//../../../constanse/webservices";

const DeleteUserForm = ({ setShowDialog, showDialog, selectedRow }) => {
  const handleDeleteUser = () => {
    // console.log("turn api", selectedRow);
    axios
      .post(
        `${USER_ACTIVE}/${selectedRow.id}/${!selectedRow.isActive}`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` } }
      )
      .then((res) => {
        // console.log("delete", res);
        setresEdit(res);
        navigate("/profile");
      })
      .catch((err) => {
        // console.log(err);
        seterrEdit(err);
      });
    setShowDialog(false);
  };
  const handleClose = () => {
    setShowDialog(false);
  };
  // console.log("row", row);

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>این کاربر حذف شود؟</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>انصراف</Button>
          <Button onClick={() => handleDeleteUser()}>تایید</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteUserForm;
