import * as React from "react";
// import { styled } from '@mui/material/styles';
//
// import TextField from "@mui/material/TextField";
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// // import {restHandler} from './../../libs/rest';
// import Link from '@mui/material/Link';
// // @ts-ignore
// // import Styles from  './LoginForm.module.scss';
// import {useState} from "react";
// import FormHelperText from '@mui/material/FormHelperText';
// // import { useForm, Controller } from "react-hook-form";
// // import { yupResolver } from "@hookform/resolvers/yup";
// // import * as yup from "yup";
// // import { SEND_CODE, PHONE_EXIST, ACTIVE_CODE, LOGIN} from "../../constanse/webservices";
// // import axios from "axios";
// // import ButtonComponent from "../GlobalComponent/Button";
// import {Edit2, Eye, EyeSlash} from "iconsax-react";
// // import {IconButton} from "@material-ui/core";
// // import InputAdornment from "@material-ui/core/InputAdornment";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import {useEffect} from "react";
//
// const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
//
// const schema = yup.object().shape({
//   // email:  yup.string().matches(phoneRegExp || EmailReExp, 'Phone number is not valid')
//   code: yup.string().required().email("شماره موبایل یا ایمیل نادرست است"),
//
//   // yup.string().email().required("Email is required") || yup.string().matches(phoneRegExp, 'Phone number is not valid'),
// });
// const schemaPhone = yup.object().shape({
//   // email:  yup.string().matches(phoneRegExp || EmailReExp, 'Phone number is not valid')
//   phoneNumber: yup.string().required("شماره تلفن یا ایمیل خود را وارد کنین").matches(phoneRegExp, 'شماره موبایل نادرست است'),
//   // yup.string().email().required("Email is required") || yup.string().matches(phoneRegExp, 'Phone number is not valid'),
// });

// fieldA:
// const LightButton = styled(ButtonComponent) `
//     background-color: #C7EBCE!important;
//     display: flex;
//     justify-content: center;
//     alignItems: center;
//      font-size: 20px;
//     //&:hover: {
//     //    background-color:red;
//     //},
//     img {
//         margin-left: 8px;
//     }
// `;
export default function LoginForm() {
  return (
    <form className={"login"}>
      dsgoiyhdfgdiugh
      {/*onClick={() => reset()}*/}
      {/*<Grid container  dir="rtl" alignItems="center">*/}
      {/*  <Grid xl={4} lg={4} md={6} sm={6} xs={12} className={[Styles.loginContainer].join(" ")}>*/}
      {/*    <Grid>*/}
      {/*      /!*<img*!/*/}
      {/*      /!*  src="/assets/src/images/login-logo.svg"*!/*/}
      {/*      /!*  width="100%"*!/*/}
      {/*      /!*  height="auto"*!/*/}
      {/*      /!*  alt="logo"*!/*/}
      {/*    <Grid>*/}
      {/*/!*      <Typography mt="56px" variant='h5' style={{color: '#3F3F3F'}}>*!/*/}
      {/*/!*        ورود یا عضویت*!/*/}
      {/*/!*      </Typography>*!/*/}
      {/*/!*    </Grid>*!/*/}
      {/*/!*    <Grid mt="32px">*!/*/}
      {/*/!*      <label color="#737373">{LoadLabel()}</label>*!/*/}
      {/*/!*    </Grid>*!/*/}
      {/*/!*    <Grid md={10} xs={12} mt="8px">*!/*/}
      {/*/!*      {LoginField()}*!/*/}
      {/*/!*      {errors?.phoneNumber && <FormHelperText id="component-error-text" style={{color: "#DA100B", textAlign: "right", fontSize: 16}}>{errors?.phoneNumber?.message}</FormHelperText>}*!/*/}
      {/*/!*    </Grid>*!/*/}
      {/*/!*    <Grid md={10} xs={12} mt="24px">*!/*/}
      {/*/!*      {LoginButton()}*!/*/}
      {/*/!*      /!*<ButtonComponent islog={true} label="ورود" onClick={handleSubmit(onSubmit)}/>*!/*!/*/}
      {/*/!*    </Grid>*!/*/}
      {/*/!*    <Grid mt="38px">*!/*/}
      {/*/!*      <Link underline="none" color="#737373" href="/">بازگشت به صفحه اصلی</Link>*!/*/}
      {/*/!*    </Grid>*!/*/}
      {/*/!*  </Grid>*!/*/}
      {/*/!*  <Grid className={[Styles.container, "d-md-block d-none"].join(" ")} xl={8} lg={8} md={6} sm={6} xs={12} >*!/*/}
      {/*/!*  </Grid>*!/*/}
      {/*/!*</Grid>*!/*/}
    </form>
  );
}
