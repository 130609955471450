/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfilesList from "examples/Lists/ProfilesList";
import SellersList from "examples/Lists/SellersList";
import DriversList from "examples/Lists/DriverList";
import StoreKeeperList from "examples/Lists/StoreKeepersList";

// Overview page components
import Header from "layouts/profile/components/Header";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

import { setDirection, useMaterialUIController } from "../../context";
import axios from "axios";
import { ADD_DRIVER_ROLE, USER_LIST } from "../../constanse/webservices";
import EditUserForm from "./components/EditUser";
import DeleteUserForm from "./components/DeleteUser";
import Icon from "@mui/material/Icon";
import { IconButton } from "@mui/material";
import dialog from "assets/theme/components/dialog";

function Overview() {
  const [, dispatch] = useMaterialUIController();
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  React.useEffect(() => {
    setDirection(dispatch, "rtl");

    return () => setDirection(dispatch, "ltr");
  }, []);
  React.useEffect(() => {
    let role = "moshtari";
    switch (tabValue) {
      case 1:
        role = "foroshandeh";
        break;
      case 2:
        role = "ranandeh";
        break;
      case 3:
        role = "anbardar";
        break;
      default:
        role = "moshtari";
        break;
    }
    // console.log(tabValue, role);
    axios
      .post(
        `${USER_LIST}/admin?role=${role}`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` } }
      )
      .then((res) => {
        console.log(res, "/profileeeeeeeeeeeeeeeeeee");
        setData(res.data.result);
        console.log("user", res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [tabValue]);

  const getEditditRows = (row) => {
    let actions = (
      <div>
        <span
          onClick={() => {
            setShowDialog(true);
            setDialogContent("edit");
            setSelectedRow(row);
          }}
        >
          <IconButton>
            <Icon>edit</Icon>
          </IconButton>
        </span>
        <span
          onClick={() => {
            setShowDialog(true);
            setDialogContent("delete");
            setSelectedRow(row);
            setData(res.data.result);
            // console.log("row", row);
          }}
        >
          <IconButton>
            <Icon>delete</Icon>
          </IconButton>
        </span>
      </div>
    );

    return actions;
  };

  const getTableRows = () => {
    let adaptedRows = data.map((row, index) => ({
      ...row,
      actions: getEditditRows(row),
    }));

    return adaptedRows;
  };

  return (
    <DashboardLayout>
      {showDialog && selectedRow && (
        <>
          {dialogContent === "edit" && (
            <EditUserForm
              data={selectedRow}
              showDialog={showDialog}
              setShowDialog={setShowDialog}
            />
          )}
          {dialogContent === "delete" && (
            <DeleteUserForm
              selectedRow={selectedRow}
              showDialog={showDialog}
              setShowDialog={setShowDialog}
            />
          )}
        </>
      )}
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header onClick={(tab) => setTabValue(tab)}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} xl={12}>
              {tabValue === 0
                ? data && (
                    <ProfilesList
                      showDialog={showDialog}
                      setShowDialog={setShowDialog}
                      title="conversations"
                      rows={getTableRows()}
                      shadow={false}
                    />
                  )
                : tabValue === 1
                ? data && (
                    <SellersList
                      showDialog={showDialog}
                      setShowDialog={setShowDialog}
                      title="conversations"
                      rows={getTableRows()}
                      shadow={false}
                    />
                  )
                : tabValue === 2
                ? data && (
                    <DriversList
                      showDialog={showDialog}
                      setShowDialog={setShowDialog}
                      title="conversations"
                      rows={getTableRows()}
                      shadow={false}
                    />
                  )
                : tabValue === 3 &&
                  data && (
                    <StoreKeeperList
                      showDialog={showDialog}
                      setShowDialog={setShowDialog}
                      title="conversations"
                      rows={getTableRows()}
                      shadow={false}
                    />
                  )}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
