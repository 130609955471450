/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";
import { LOGIN, REGISTER_DRIVER, USER_INFO } from "../../../constanse/webservices";

function Basic() {
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSubmit = (e) => {
    axios
      .post(`${LOGIN}`, {
        password: data.password,
        username: data.username,
      })
      .then((res) => {
        console.log(res, res.data.result.accessToken);
        axios
          .get(`${USER_INFO}`, {
            headers: { Authorization: `Bearer ${res.data.result.accessToken}` },
          })
          .then((rest) => {
            console.log(rest, rest.data.roles.includes("Admin"));
            if (rest.data.roles.includes("Admin")) {
              localStorage.setItem("adminAccess", res.data.result.accessToken);
              navigate("/dashboard");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            ورود
          </MDTypography>
          {/*<Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>*/}
          {/*  <Grid item xs={2}>*/}
          {/*    <MDTypography component={MuiLink} href="#" variant="body1" color="white">*/}
          {/*      <FacebookIcon color="inherit" />*/}
          {/*    </MDTypography>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={2}>*/}
          {/*    <MDTypography component={MuiLink} href="#" variant="body1" color="white">*/}
          {/*      <GitHubIcon color="inherit" />*/}
          {/*    </MDTypography>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={2}>*/}
          {/*    <MDTypography component={MuiLink} href="#" variant="body1" color="white">*/}
          {/*      <GoogleIcon color="inherit" />*/}
          {/*    </MDTypography>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="username"
                fullWidth
                name={"username"}
                value={data?.username}
                onChange={(e) => setData({ ...data, username: e.target.value })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="password"
                fullWidth
                name={"password"}
                value={data?.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                ورود
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
