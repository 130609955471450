// BASE URL
const BASE_URL = "https://ketabtusapi.tribitgroup.com/api/v1"; // stage - dev

const TOKEN_ADMIN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJqdGkiOiI2ZDhhNWI5Zi1kYzlhLWZkNzEtMDg0Ny0zYTA2NzFkNWViODIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImVjNzdhYjI0LWU1NDMtNGU4ZC1hMzFmLTNiYTFkNzZhNjU2ZCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiZXhwIjoxNjYzNzYzMjU5LCJpc3MiOiJodHRwOi8va2V0YWJ0b3MudHJpYml0Z3JvdXAuY29tIiwiYXVkIjoiaHR0cDovL2tldGFidG9zLnRyaWJpdGdyb3VwLmNvbSJ9.c_I_VMCv1zSAIubUzxlxMRoE_lOPeWzarntDRRj5Lvw"; // stage - dev

// FORGOT PASSWORD API
export const SEND_CODE = `${BASE_URL}/authenticate/send-activation-code`;
export const PHONE_EXIST = `${BASE_URL}/authenticate/phone-exists`;
export const ACTIVE_CODE = `${BASE_URL}/authenticate/activation-code`;
export const LOGIN = `${BASE_URL}/authenticate/login`;
export const USER_INFO = `${BASE_URL}/authenticate/user-info`;
export const CHANGEPASSWORD = `${BASE_URL}/authenticate/change-password`;
export const REGISTER = `${BASE_URL}/authenticate/register-moshtari`;
export const EDITUSER = `${BASE_URL}/authenticate/edit`;
export const ADD_MOSHARI_ROLE = `${BASE_URL}/authenticate/switch-moshtari-role`;
export const REGISTER_SELLER = `${BASE_URL}/authenticate/register-foroshandeh`;
export const ADD_SELLER_ROLE = `${BASE_URL}/authenticate/switch-foroshandeh-role`;
export const REGISTER_DRIVER = `${BASE_URL}/authenticate/register-ranandeh`;
export const ADD_DRIVER_ROLE = `${BASE_URL}/authenticate/switch-ranandeh-role`;
export const REGISTER_ُSTOREKEEPER = `${BASE_URL}/authenticate/register-anbardar`;
export const ADD_ُSTOREKEEPER_ROLE = `${BASE_URL}/authenticate/switch-anbardar-role`;
export const USER_LIST = `${BASE_URL}/authenticate/users`;
export const USER_ACTIVE = `${BASE_URL}/authenticate/active-status-change`;

export const AREA_API = `${BASE_URL}/Area`;
export const STATE_API = `${BASE_URL}/State/all`;
export const CITY_API = `${BASE_URL}/City/all`;
export const COUNTY_API = `${BASE_URL}/County/all`;

export const PRODUCTS_ALL = `${BASE_URL}/Merchandise/all`;
export const PRODUCT_SINGLE = `${BASE_URL}/Merchandise`;
export const PRODUCTS_ADD = `${BASE_URL}/Merchandise/add`;
export const PRODUCTS_DELETE = `${BASE_URL}/Merchandise/delete`;
export const PRODUCTS_EDIT = `${BASE_URL}/Merchandise/update`;

export const CATEGORY_ALL = `${BASE_URL}/Category/all`;
export const CATEGORY_ADD = `${BASE_URL}/Category/add`;
export const CATEGORY_DELETE = `${BASE_URL}/Category/delete`;
export const CATEGORY_EDIT = `${BASE_URL}/Category/update`;

export const UNIT_ALL = `${BASE_URL}/Unit/all`;
export const UNIT_ADD = `${BASE_URL}/Unit/add`;
export const UNIT_DELETE = `${BASE_URL}/Unit/delete`;
export const UNIT_EDIT = `${BASE_URL}/Unit/update`;

export const SHOP_API = `${BASE_URL}/Shop/all`;
