/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import DataTable from "../../Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";
import logoXD from "../../../assets/images/small-logos/logo-xd.svg";
import team1 from "../../../assets/images/team-1.jpg";
import team2 from "../../../assets/images/team-2.jpg";
import team3 from "../../../assets/images/team-3.jpg";
import team4 from "../../../assets/images/team-4.jpg";
import MDProgress from "../../../components/MDProgress";
import logoAtlassian from "../../../assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "../../../assets/images/small-logos/logo-slack.svg";
import logoSpotify from "../../../assets/images/small-logos/logo-spotify.svg";
import logoJira from "../../../assets/images/small-logos/logo-jira.svg";
import logoInvesion from "../../../assets/images/small-logos/logo-invision.svg";
const avatars = (members) =>
  members.map(([image, name]) => (
    <Tooltip key={name} title={name} placeholder="bottom">
      <MDAvatar
        src={image}
        alt="name"
        size="xs"
        sx={{
          border: ({ borders: { borderWidth }, palette: { white } }) =>
            `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",

          "&:not(:first-of-type)": {
            ml: -1.25,
          },

          "&:hover, &:focus": {
            zIndex: "10",
          },
        }}
      />
    </Tooltip>
  ));

const Company = ({ image, name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
      {name}
    </MDTypography>
  </MDBox>
);
const columns = [
  { id: 1, Header: "نام", accessor: "firstName", width: "45%", align: "left" },
  { id: 2, Header: "نام خانوادگی", accessor: "lastName", width: "10%", align: "left" },
  { id: 3, Header: "شماره تلفن", accessor: "phoneNumber", align: "left" },
  // { id: 4, Header: "نام کاربری", accessor: "userName", align: "center" },
  { id: 5, Header: "ایمیل", accessor: "normalizedEmail", align: "left" },
  // { Header: "userName", accessor: "userName", align: "center" },
  { id: 7, Header: "عملیات", accessor: "actions", align: "left" },
];
const rows = [
  {
    firstName: <Company image={logoXD} name="Material UI XD Version" />,
    lastName: (
      <MDBox display="flex" py={1}>
        {avatars([
          [team1, "Ryan Tompson"],
          [team2, "Romina Hadid"],
          [team3, "Alexander Smith"],
          [team4, "Jessica Doe"],
        ])}
      </MDBox>
    ),
    phoneNumber: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        $14,000
      </MDTypography>
    ),
    userName: (
      <MDBox width="8rem" textAlign="left">
        <MDProgress value={60} color="info" variant="gradient" label={false} />
      </MDBox>
    ),
  },
  {
    firstName: <Company image={logoAtlassian} name="Add Progress Track" />,
    lastName: (
      <MDBox display="flex" py={1}>
        {avatars([
          [team2, "Romina Hadid"],
          [team4, "Jessica Doe"],
        ])}
      </MDBox>
    ),
    phoneNumber: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        $3,000
      </MDTypography>
    ),
    userName: (
      <MDBox width="8rem" textAlign="left">
        <MDProgress value={10} color="info" variant="gradient" label={false} />
      </MDBox>
    ),
  },
  {
    firstName: <Company image={logoSlack} name="Fix Platform Errors" />,
    lastName: (
      <MDBox display="flex" py={1}>
        {avatars([
          [team1, "Ryan Tompson"],
          [team3, "Alexander Smith"],
        ])}
      </MDBox>
    ),
    phoneNumber: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Not set
      </MDTypography>
    ),
    userName: (
      <MDBox width="8rem" textAlign="left">
        <MDProgress value={100} color="success" variant="gradient" label={false} />
      </MDBox>
    ),
  },
  {
    firstName: <Company image={logoSpotify} name="Launch our Mobile App" />,
    lastName: (
      <MDBox display="flex" py={1}>
        {avatars([
          [team4, "Jessica Doe"],
          [team3, "Alexander Smith"],
          [team2, "Romina Hadid"],
          [team1, "Ryan Tompson"],
        ])}
      </MDBox>
    ),
    phoneNumber: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        $20,500
      </MDTypography>
    ),
    userName: (
      <MDBox width="8rem" textAlign="left">
        <MDProgress value={100} color="success" variant="gradient" label={false} />
      </MDBox>
    ),
  },
  {
    firstName: <Company image={logoJira} name="Add the New Pricing Page" />,
    lastName: (
      <MDBox display="flex" py={1}>
        {avatars([[team4, "Jessica Doe"]])}
      </MDBox>
    ),
    phoneNumber: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        $500
      </MDTypography>
    ),
    userName: (
      <MDBox width="8rem" textAlign="left">
        <MDProgress value={25} color="info" variant="gradient" label={false} />
      </MDBox>
    ),
  },
  {
    firstName: <Company image={logoInvesion} name="Redesign New Online Shop" />,
    lastName: (
      <MDBox display="flex" py={1}>
        {avatars([[team4, "Jessica Doe"]])}
      </MDBox>
    ),
    phoneNumber: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        $20,500
      </MDTypography>
    ),
    userName: (
      <MDBox width="8rem" textAlign="left">
        <MDProgress value={100} color="success" variant="gradient" label={false} />
      </MDBox>
    ),
  },
];
function SellersList({ title, rows, shadow }) {
  const renderProfiles = rows.map(({ image, name, description, action }) => (
    <MDBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1}>
      {/*<MDBox mr={2}>*/}
      {/*  <MDAvatar src={image} alt="something here" shadow="md" />*/}
      {/*</MDBox>*/}
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox p={2}>
        <MDBox component="div" display="flex" flexDirection="column" p={0} mx={2} my={0}>
          <MDButton
            circular={false}
            iconOnly={false}
            color={"success"}
            variant="contained"
            style={{ width: "fit-content" }}
          >
            <Link
              to="/authentication/storeKeeper"
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              اضافه کردن انباردار جدید
            </Link>
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              bgColor="success"
              borderRadius="lg"
              coloredShadow="success"
            >
              <MDTypography variant="h6" color="white">
                جدول انباردار ها
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
SellersList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
SellersList.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default SellersList;
