// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignOut from "layouts/authentication/sign-out";

// @mui icons
import Icon from "@mui/material/Icon";
import Products from "./layouts/products";
import AddProductForm from "./layouts/products/components/AddForms/AddProductForm";
import EditProductForm from "./layouts/products/components/EditForms/EditProductForm";
import AddCategoryForm from "./layouts/products/components/AddForms/AddCategoryForm";
import AddUnitForm from "./layouts/products/components/AddForms/AddUnitForm";

const routes = [
  {
    type: "collapse",
    name: "داشبورد",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "محصولات",
    key: "products",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    type: "none",
    name: "محصولات",
    key: "products",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/products/add",
    component: <AddProductForm />,
  },
  {
    type: "none",
    name: "محصولات",
    key: "products",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/products/edit/:productID",
    component: <EditProductForm />,
  },
  {
    type: "none",
    name: "محصولات",
    key: "products",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/products/categories/add",
    component: <AddCategoryForm />,
  },
  {
    type: "none",
    name: "محصولات",
    key: "products",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/products/unit/add",
    component: <AddUnitForm />,
  },
  // {
  //   type: "collapse",
  //   name: "جدول ها",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "صورت حساب ها",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "پیام ها",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "collapse",
    name: "کاربران",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  // {
  //   type: "collapse",
  //   name: "ثبت نام",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  {
    type: "collapse",
    name: "خروج",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
