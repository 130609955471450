import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfilesList from "examples/Lists/ProfilesList";
import SellersList from "examples/Lists/SellersList";
import DriversList from "examples/Lists/DriverList";
import StoreKeeperList from "examples/Lists/StoreKeepersList";

// Overview page components
import Header from "layouts/products/components/Header";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

import { setDirection, useMaterialUIController } from "../../context";
import axios from "axios";
import { ADD_DRIVER_ROLE, USER_LIST } from "../../constanse/webservices";
import ProductsList from "./components/Lists/ProductList";
import { Category2 } from "iconsax-react";
import CategoryList from "./components/Lists/CategoryList";
import UnitList from "./components/Lists/UnitList";

function Overview() {
  const [, dispatch] = useMaterialUIController();
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState(null);

  React.useEffect(() => {
    setDirection(dispatch, "rtl");

    return () => setDirection(dispatch, "ltr");
  }, []);

  console.log(tabValue);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header onClick={(tab) => setTabValue(tab)}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} xl={12}>
              {tabValue === 0 ? (
                <ProductsList />
              ) : tabValue === 1 ? (
                <CategoryList title="conversations" rows={data} shadow={false} />
              ) : (
                tabValue === 2 && <UnitList title="conversations" rows={data} shadow={false} />
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
