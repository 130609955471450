import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { EDITUSER } from "constanse/webservices";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { TextField, Typography } from "@mui/material";
import { Label } from "@mui/icons-material";

const EditUserForm = ({ data, setShowDialog, showDialog }) => {
  const [formData, setFormData] = React.useState({
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    // phoneNumber: data.phoneNumber || "",
    email: data.email || "",
    dateOfBirth: data.shamsiDateOfBirth || "",
    nationalCode: data.nationalCode || "",
  });
  const [resEdit, setresEdit] = React.useState();
  const [errEdit, seterrEdit] = React.useState();

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleSubmit = (e) => {
    setresEdit();
    seterrEdit();
    console.log("formData", formData);
    axios
      .put(
        `${EDITUSER}`,
        {
          userId: data.id,
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          dateOfBirth: formData.dateOfBirth,
          nationalCode: formData.nationalCode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adminAccess")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setresEdit(res);
        navigate("/profile");
      })
      .catch((err) => {
        console.log(err);
        seterrEdit(err);
      });
  };
  console.log("formData", formData);

  return (
    <div>
      {console.log("dataaaaaa", data)}
      <Dialog open={showDialog} onClose={handleClose}>
        <DialogTitle>ویرایش کاربر</DialogTitle>
        <DialogContent>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  textAlign="center"
                  type="text"
                  label="نام"
                  fullWidth
                  name={"firstName"}
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      firstName: e.target.value,
                    })
                  }
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="نام خانوادگی"
                  fullWidth
                  name={"lastName"}
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      lastName: e.target.value,
                    })
                  }
                />
              </MDBox>
              {/* <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="تلفن"
                  fullWidth
                  name={"phoneNumber"}
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                />
              </MDBox> */}
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="کدملی"
                  fullWidth
                  name={"nationalCode"}
                  value={formData.nationalCode}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      nationalCode: e.target.value,
                    })
                  }
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="ایمیل"
                  fullWidth
                  name={"email"}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    })
                  }
                />
              </MDBox>
              <MDBox mb={2}>
                {/*<MDInput
                  type="date"
                  label="تاریخ تولد"
                  fullWidth
                  name={"dateOfBirth"}
                  value={formData.dateOfBirth || new Date()}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      dateOfBirth: e.value,
                    })
                  }
                /> */}
                <Typography>تاریخ تولد</Typography>
                <DatePicker
                  calendar={persian}
                  locale={persian_fa}
                  calendarPosition="bottom-right"
                  // value={formData.dateOfBirth || new Date()}
                  value={formData.dateOfBirth}
                  // onChange={setValue}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      dateOfBirth: `${e.year}/${e.monthIndex < 10 ? "0" : ""}${e.monthIndex + 1}/${
                        e.day < 10 ? "0" : ""
                      }${e.day}`,
                    })
                  }
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            mt={4}
            mb={1}
          >
            {resEdit ? "تغییرات با موفقیت ذخیره شد" : ""}
            {errEdit ? "با خطا مواجه شد! فیلد هارا بررسی کنید" : ""}
          </MDBox>
        </DialogActions>
        <DialogActions>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            mt={4}
            mb={1}
          >
            <MDBox sx={{ width: "100%" }} m={1}>
              <MDButton onClick={() => handleSubmit()} variant="gradient" color="success" fullWidth>
                ویرایش
              </MDButton>
            </MDBox>
            <MDBox sx={{ width: "100%" }} m={1}>
              <MDButton onClick={() => handleClose()} variant="gradient" color="success" fullWidth>
                لغو
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditUserForm;
