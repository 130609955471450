import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { setDirection, useMaterialUIController } from "../../../../../context";
import axios from "axios";
import {
  PRODUCT_SINGLE,
  CATEGORY_ALL,
  PRODUCTS_EDIT,
  UNIT_ALL,
} from "../../../../../constanse/webservices";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import Api from "Api";

function Overview() {
  const navigate = useNavigate();
  const { productID } = useParams();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [, dispatch] = useMaterialUIController();
  const [tabValue, setTabValue] = useState(0);
  const [categories, setCategories] = useState(null);
  const [units, setUnit] = useState(null);
  const [data, setData] = useState({});
  // console.log("productID", productID);
  // console.log("data", data);
  React.useEffect(() => {
    setDirection(dispatch, "rtl");

    return () => setDirection(dispatch, "rtl");
  }, []);

  React.useEffect(() => {
    // axios
    //   .get(`${PRODUCT_SINGLE}?id=${productID}`, {
    //     headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` },
    //   })
    //   .then((res) => {
    //     console.log("Product", res.data);
    //     res.data.catName = "hi";
    //     setSelectedProduct(res.data);
    //     setData(res.data);
    //     console.log("catname", res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // const selectedProduct = () => {
    //   const nameObj = categories?.result?.find((item2) => {
    //     return item2.id === categoryId;
    //   });
    //   let catTitle = nameObj?.title;
    // };
    // console.log("data", data);

    axios
      .post(
        `${CATEGORY_ALL}`,
        {
          page: 0,
          count: 0,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` } }
      )
      .then((res) => {
        // console.log(res, "/profile");
        setCategories(res.data.result);

        axios
          .get(`${PRODUCT_SINGLE}?id=${productID}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` },
          })
          .then((prodRes) => {
            console.log("Product", prodRes.data);

            const fff =
              res.data.result.find((item2) => {
                return item2.id == prodRes.data.categoryId;
              }).title || "not Found";
            setSelectedProduct((val) => setSelectedProduct({ ...val, catName: fff }));
            prodRes.data.catName = fff;
            setSelectedProduct(prodRes.data);
            setData(prodRes.data);
            console.log("catname", prodRes.data);
          })
          .catch((err) => {
            console.log(err);
          });

        console.log(categories, "categories");
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${UNIT_ALL}`,
        {
          page: 0,
          count: 0,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` } }
      )
      .then((res) => {
        // console.log(res, "/profile");
        setUnit(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const params = useParams();

  const handleSubmit = () => {
    axios
      .put(
        `${PRODUCTS_EDIT + "/" + productID}`,
        {
          photo: data?.photo,
          name: data?.title,
          CategoryId: data?.category,
          newUnitId: data?.unit,
          coinPrice: data?.price,
          CoinOffPrice: data?.discount,
          isForSale: data?.forSale,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("adminAccess")}`,
          },
        }
      )
      .then((res) => {
        // console.log(res, "res");
        // setData(res.data.result);
        // navigate("/products");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(data, categories, units);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Card sx={{ height: "100%" }}>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                textAlign="center"
                type="file"
                fullWidth
                name={"photo"}
                value={data.image}
                onChange={(e) => setData({ ...data, photo: e.target.files[0] })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                textAlign="center"
                type="text"
                label="نام محصول"
                fullWidth
                name={"title"}
                value={data.name}
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
            </MDBox>
            <MDBox mb={2}>
              {/* <MDInput
                type="text"
                label="تعداد محصول"
                fullWidth
                name={"count"}
                value={data.count}
                onChange={(e) => setData({ ...data, count: e.target.value })}
              /> */}
            </MDBox>
            {selectedProduct && selectedProduct.catName}
            {/* {categories && categories[0].id} */}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="تعداد سکه"
                fullWidth
                name={"price"}
                value={data?.coinPrice}
                onChange={(e) => setData({ ...data, price: e.target.value })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="تعداد سکه تخفیفی"
                fullWidth
                name={"discount"}
                value={data?.coinOffPrice}
                onChange={(e) => setData({ ...data, discount: e.target.value })}
              />
            </MDBox>
            <MDBox mb={2} sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">دسته بندی</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant={"outlined"}
                  fullWidth
                  style={{ height: 40 }}
                  label="دسته بندی"
                  // value={e.target.value}
                  onChange={(e) => setData({ ...data, category: e.target.value })}
                >
                  {categories?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mb={2} sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">واحد</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant={"outlined"}
                  fullWidth
                  style={{ height: 40 }}
                  label="واحد"
                  onChange={(e) => setData({ ...data, unit: e.target.value })}
                >
                  {units?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.smallUnitName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </MDBox>
            {/* <MDBox mb={2} mx={2}>
              <InputLabel id="demo-simple-select-label" style={{ paddingBottom: 8 }}>
                برای فروش
              </InputLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.forSale}
                      onChange={(e) => setData({ ...data, forSale: e.target.checked })}
                    />
                  }
                  label="بله"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!data.forSale}
                      onChange={(e) => setData({ ...data, forSale: !e.target.checked })}
                    />
                  }
                  label="خیر"
                />
              </FormGroup>
            </MDBox> */}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="توضیحات"
                fullWidth
                name={"pathToParent"}
                value={data?.pathToParent}
                multiline={true}
                rows={4}
                onChange={(e) => setData({ ...data, pathToParent: e.target.value })}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                ثبت محصول جدید
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
