/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import DataTable from "../../Tables/DataTable";
import EnhancedTable from "../../../layouts/profile/components/Table";
import logoXD from "../../../assets/images/small-logos/logo-xd.svg";
import team1 from "../../../assets/images/team-1.jpg";
import team2 from "../../../assets/images/team-2.jpg";
import team3 from "../../../assets/images/team-3.jpg";
import team4 from "../../../assets/images/team-4.jpg";
import MDProgress from "../../../components/MDProgress";
import logoAtlassian from "../../../assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "../../../assets/images/small-logos/logo-slack.svg";
import logoSpotify from "../../../assets/images/small-logos/logo-spotify.svg";
import logoJira from "../../../assets/images/small-logos/logo-jira.svg";
import logoInvesion from "../../../assets/images/small-logos/logo-invision.svg";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";

const avatars = (members) =>
  members.map(([image, name]) => (
    <Tooltip key={name} title={name} placeholder="bottom">
      <MDAvatar
        src={image}
        alt="name"
        size="xs"
        sx={{
          border: ({ borders: { borderWidth }, palette: { white } }) =>
            `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",

          "&:not(:first-of-type)": {
            ml: -1.25,
          },

          "&:hover, &:focus": {
            zIndex: "10",
          },
        }}
      />
    </Tooltip>
  ));

const Company = ({ image, name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
      {name}
    </MDTypography>
  </MDBox>
);
const columns = [
  { id: 1, Header: "نام", accessor: "firstName", width: "15%", align: "left" },
  { id: 2, Header: "نام خانوادگی", accessor: "lastName", width: "15%", align: "left" },
  { id: 3, Header: "شماره تلفن", accessor: "phoneNumber", align: "left" },
  // { id: 4, Header: "نام کاربری", accessor: "userName", align: "left" },
  { id: 5, Header: "ایمیل", accessor: "normalizedEmail", align: "left" },
  { id: 6, Header: "اعنبار کاربر", accessor: "accessFailedCount", align: "left" },
  { id: 7, Header: "استاتوس", accessor: "isActive", align: "left" },
  { id: 8, Header: "عملیات", accessor: "actions", width: "10%", align: "left" },
];

function ProfilesList({ title, rows, shadow, showDialog, setShowDialog, dialogContent }) {
  console.log("rows", rows);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox p={2}>
        <MDBox component="div" display="flex" flexDirection="column" p={0} mx={2} my={0}>
          <MDButton
            circular={false}
            iconOnly={false}
            color={"success"}
            variant="gradient"
            // onClick={() => history.push("/authentication/customer")}
            style={{ width: "fit-content" }}
          >
            <Link
              to="/authentication/customer"
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              افزودن مشتری جدید
            </Link>
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              bgColor="success"
              borderRadius="lg"
              coloredShadow="success"
            >
              <Button onClick={handleOpen}>Open modal</Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Text in a modal
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                  </Typography>
                </Box>
              </Modal>

              <MDTypography variant="h6" color="white">
                جدول مشتری ها
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {rows && (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                  dialogContent={dialogContent}
                />
              )}
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
