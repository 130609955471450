import axios from "axios";
export default class Api {
  constructor() {
    this.get.bind(this);
    this.post.bind(this);
    this.put.bind(this);
    this.delete.bind(this);
    this.postWithFile.bind(this);
    this.putWithFile.bind(this);
    this.postAxios.bind(this);
    this.BASE__URL.bind(this);
    this.TOKEN__ADMIN.bind(this);
  }

  BASE__URL() {
    const BASE__URL = "https://ketabtusapi.tribitgroup.com/"; // stage - dev
  }
  TOKEN__ADMIN() {
    const TOKEN__ADMIN =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJqdGkiOiI2ZDhhNWI5Zi1kYzlhLWZkNzEtMDg0Ny0zYTA2NzFkNWViODIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImVjNzdhYjI0LWU1NDMtNGU4ZC1hMzFmLTNiYTFkNzZhNjU2ZCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiZXhwIjoxNjYzNzYzMjU5LCJpc3MiOiJodHRwOi8va2V0YWJ0b3MudHJpYml0Z3JvdXAuY29tIiwiYXVkIjoiaHR0cDovL2tldGFidG9zLnRyaWJpdGdyb3VwLmNvbSJ9.c_I_VMCv1zSAIubUzxlxMRoE_lOPeWzarntDRRj5Lvw"; // stage - dev
  }
  postAxios(url, data, config) {
    axios.post(url, data, config);
  }
  get(url, options = {}) {
    options = {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    return fetch(url, options);
  }
  post(url, options = {}) {
    options = {
      ...options,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    return fetch(url, options);
  }
  postWithFile(url, body) {
    var f = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        f.append(key, body[key]);
      }
    }
    let options = {
      body: f,
      method: "post",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    return fetch(url, options);
  }
  putWithFile(url, body) {
    var f = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        f.append(key, body[key]);
      }
    }
    let options = {
      body: f,
      method: "put",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    return fetch(url, options);
  }
  put(url, options) {
    options = {
      ...options,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    return fetch(url, options);
  }
  delete(url, options = {}) {
    options = {
      ...options,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    return fetch(url, options);
  }
}
