/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components

// Data

import { setDirection, useMaterialUIController } from "../../../../../context";
import axios from "axios";
import { CATEGORY_ALL, UNIT_ADD, UNIT_ALL } from "../../../../../constanse/webservices";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

function Overview() {
  const navigate = useNavigate();
  const [, dispatch] = useMaterialUIController();
  const [data, setData] = useState({});

  React.useEffect(() => {
    setDirection(dispatch, "rtl");

    return () => setDirection(dispatch, "rtl");
  }, []);

  const handleSubmit = () => {
    axios
      .post(
        `${UNIT_ADD}`,
        {
          smallUnitName: data?.smallUnitName,
          bigUnitName: data?.bigUnitName,
          smallToBigCoefficient: data?.smallToBigCoefficient,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("adminAccess")}` } }
      )
      .then((res) => {
        // console.log(res, "/profile");
        // setData(res.data.result);
        navigate("/products");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(data);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Card sx={{ height: "100%" }}>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                textAlign="center"
                type="text"
                label="نام واحد"
                fullWidth
                name={"bigUnitName"}
                value={data?.bigUnitName}
                onChange={(e) => setData({ ...data, bigUnitName: e.target.value })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="نام مخفف"
                fullWidth
                name={"smallUnitName"}
                value={data?.smallUnitName}
                onChange={(e) => setData({ ...data, smallUnitName: e.target.value })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="ظریب تاثیر"
                fullWidth
                name={"smallToBigCoefficient"}
                value={data?.smallToBigCoefficient}
                onChange={(e) => setData({ ...data, smallToBigCoefficient: e.target.value })}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                ثبت واحد جدید
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
